import { nextI18nextConfig } from '@goed-platform/shared/constants';
import { CustomAppProps, RoutesEnum } from '@goed-platform/shared/types';
import { BaseLayout, ErrorContent } from '@goed-platform/shared/ui';
import { isBrowser, LogWarning } from '@goed-platform/shared/utils';
import { GetStaticProps } from 'next';
import { UserConfig } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { FC, useEffect } from 'react';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'], nextI18nextConfig as UserConfig)),
        },
    };
};

const Custom404: FC<CustomAppProps> = ({ navigation }: CustomAppProps): JSX.Element => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isBrowser()) {
            LogWarning('404 - Page not found');
        }
    }, []);

    return (
        <BaseLayout
            navigation={navigation}
            showSubHeader={false}
            showFooterNavigation={false}
            showMinimalizedFooter={true}
            showCustomerBar={false}
        >
            <ErrorContent error={404} homepageUrl={RoutesEnum.home} />
        </BaseLayout>
    );
};

export default Custom404;
